<template>
  <a-modal :title="title" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确定" width="75%" :footer="null"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <div class="clearfix table-tools">
      <!-- <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
              <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div> -->
    </div>
    <a-table :columns="columns" size="small" :dataSource="list" :bordered='false' :pagination="false" rowKey="contract_id">
        <template slot="index" slot-scope="text, record , index">
            <span v-if="index+1 == list.length">合计</span>
            <span v-else>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>

        <template slot="studentName" slot-scope="text, record, index">
          <span v-if="index+1 == list.length"></span>
          <LUser v-else :data="record"/>
        </template>
        <template slot="out_trade_no" slot-scope="text,record">
          <a href="javascript:;">{{ text }}</a>
        </template>

    </a-table>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
  </a-modal>
</template>

<script>
import moment from 'moment'
import ranges from "@/common/mixins/ranges"
import tableMixins from '@/common/mixins/table'

const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width: '160px', dataIndex: 'student_name', key: 'name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '签约合同号', width: '160px', dataIndex: 'contract_no', key: '1', scopedSlots: { customRender: 'out_trade_no' },fixed: 'left',},
  { title: '签约校区', dataIndex: 'studio_name', key: '3' ,ellipsis: true,sorter:()=>{}},
  { title: '课程顾问', dataIndex: 'course_consultant', key: 'course_consultant' , ellipsis: true,sorter:()=>{}},
  { title: '课程科目', dataIndex: 'course_cate_id', key: '5' ,ellipsis: true,width: '120px'},
  { title: '签约金额', dataIndex: 'contract_costs', key: '7',align:"center" ,sorter:()=>{},width: '100px'},
  { title: '经办人', width: '80px',dataIndex: 'created_by', key: '14', ellipsis: true},
  { title: '来源渠道', dataIndex: 'channel_id', key: 'channel_id' ,ellipsis: true},
  { title: '操作时间', width: '180px', dataIndex: 'created_at', key: '15',sorter:()=>{},align:'center'}
]
    export default {
        name:"recordList",
        inject: ['parent'],
        props: {
            item: Object,
            start_date: String,
            end_date: String,
            course_cate_id: String,
        },
        mixins: [ tableMixins, ranges ],
        data() {
            return {
                loading: false,
                visible: false,
                confirmLoading: false,
                exportLoading: false,
                columns,
                list: [],
                searchData:{},
                studios:[],
                consultants:[],
                title:'',
            }
        },
        created () {
            this.visible = true
            this.title = `业绩统计`
        },
        methods: {
            moment,
            async getList() {
                this.loading = true
                this.searchParams.search.start_date = this.start_date
                this.searchParams.search.end_date = this.end_date

                if(this.item.studio_id){
                  this.searchParams.search.studio_id = this.item.studio_id
                }
                if(this.parent.paramsData.studio_id){
                  this.searchParams.search.studio_id = this.parent.paramsData.studio_id
                }

                if(this.item.channel_id){
                  this.searchParams.search.channel_id = this.item.channel_id
                }
                if(this.parent.paramsData.channel_id){
                  this.searchParams.search.channel_id = this.parent.paramsData.channel_id
                }


                if(this.item.employee_id){
                  this.searchParams.search.course_consultant_id = this.item.employee_id
                }

                if(this.item.course_cate_id){
                  this.searchParams.search.course_cate_id = this.item.course_cate_id
                }

                if(this.parent.paramsData.course_cate_id){
                  this.searchParams.search.course_cate_id = this.parent.paramsData.course_cate_id
                }

                let res = await this.$store.dispatch('analysisCampusAchievementListAction',this.searchParams)
                this.list = res.items
                this.list.push({
                  contract_id:-1,
                  times:res._total.times,
                  gift_times:res._total.gift_times,
                  contract_costs:res._total.contract_costs,
                  contract_discount:res._total.contract_discount,
                })
                this.pageParams = res._meta
                this.loading = false
            },
            handleOk(){},
            handleCancel(){
                this.parent.hideRecord(0)
            },
            handleTime(e){
              if(e.length>0){
                this.searchParams.search.start_date = moment(e[0]).format("YYYY-MM-DD")
                this.searchParams.search.end_date = moment(e[1]).format("YYYY-MM-DD")
              }else{
                this.searchParams.search.start_date = undefined
                this.searchParams.search.end_date = undefined
              }
            },
            async toExport(){
                this.exportLoading = true
                let exportData = {
                    search:this.searchParams.search,
                }
                let res = await this.$store.dispatch('analysisCourseBalanceRevenueListExportAction', exportData)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = `课消记录.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
        },
    }
</script>

<style lang="scss">
  
</style>