<template>
    <div class="staffTaget">
      <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>财务数据分析</a-breadcrumb-item>
            <a-breadcrumb-item>业绩统计</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="main-box">
        <div class="clearfix table-tools">
            <div class="search" style="padding-bottom:10px;">
            <a-form layout='inline'>
                    <a-form-item>
                      <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item>
                      <a-radio-group :value="paramsData.filter_column" @change="handleColButChange">
                        <a-radio-button value="money">
                            金额
                        </a-radio-button>
                        <a-radio-button value="count">
                            单数
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-item>

                    <a-form-item>
                      <a-radio-group :value="paramsData.contract_type">
                        <a-radio-button @click="handleTypeButChange('-1')" value="-1">
                            付费试听
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('1')" value="1">
                            新签
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('2')" value="2">
                            续费
                        </a-radio-button>
                        <a-radio-button @click="handleTypeButChange('3')" value="3">
                            转介绍
                        </a-radio-button>
                      </a-radio-group>
                    </a-form-item>


                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :value='rangesValue'
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,2)" />
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>所属校区</span>
                        </template>
                        <a-select allowClear showSearch placeholder="请选择校区" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' :filterOption="filterOption" @change="handleChange" style="width: 220px;">
                            <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                        </a-tooltip>
                    </a-form-item>
                     <a-form-item>
                    <a-tooltip placement="topLeft" >
                        <template slot="title">
                        <span>课程科目</span>
                        </template>
                        <a-select allowClear placeholder="请选择课程科目" :filterOption="filterOption" @change="handleSubjectChange" style="width: 180px">
                        <a-select-option v-for="(d, index) of courseCategorys" :key="index" :value="d['course_cate_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                    </a-tooltip>
                    </a-form-item>
                <a-form-item>    
                  <a-button type="primary" :loading="loading" @click="searchList">搜索</a-button>
                </a-form-item>
            </a-form>
            </div>
        </div>
        <div style="padding:20px" class="dashboard bk-group">
            <a-row class="mb16" :gutter="16">
                  <a-col :lg="24" :xl="24">
                      <income :loading='loading' :title='title'  :item='incomeDate' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                  </a-col>
            </a-row>

            <div style="background:#fff;">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="course_cate_id"
                :columns="course_columns" :dataSource="course_list" :scroll="{ x: RelativeWidth, y:500}">
                </a-table>
            </div>

            <div style="background:#fff;margin-top:16px">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="course_cate_id"
                :columns="fee_columns" :dataSource="fee_list" :scroll="{ x: RelativeWidth, y:500}">
                </a-table>
            </div>

            <div style="background:#fff;margin-top:16px">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="studio_id"
                :columns="columns" :dataSource="list" :scroll="{ x: RelativeWidth, y:500 }">
                  <template slot="name" slot-scope="text,record">
                    <div class="table-name" @click="showRecord(record)" style="cursor:pointer;color:#00cca2">{{text}}</div>
                  </template>
                </a-table>
            </div>
            <div style="background:#fff;margin:16px 0">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                  <a-table size="small" :pagination="false" :bordered='false' rowKey="channel_id"
                  :columns="channel_columns" :dataSource="channel_list" :scroll="{ x: RelativeWidth, y:500 }">
                    <template slot="name" slot-scope="text,record">
                      <div class="table-name" @click="showRecord(record)" style="cursor:pointer;color:#00cca2">{{text}}</div>
                    </template>
                  </a-table>
            </div>
            <div style="background:#fff;">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="employee_id" class="employee_list"
                :columns="employee_columns" :dataSource="employee_list" :scroll="{ x: RelativeWidth, y:500}">
                  <template slot="name" slot-scope="text,record">
                    <div class="table-name" @click="showRecord(record)" style="cursor:pointer;color:#00cca2">{{text}}</div>
                  </template>
                </a-table>
            </div>
        </div>
      </div>
      <recordList v-if="isRecordList" :item='modalData' :course_cate_id="paramsData.course_cate_id" :start_date="paramsData.start_date" :end_date="paramsData.end_date"/>
    </div>
</template>
<script>
import ranges from "@/common/mixins/ranges"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from "moment"
import income from './income'
import recordList from './recordList'
import tableMixins from '@/common/mixins/table'
export default {
    provide() {
      return {
        parent: this
      }
    },
    data(){
        return{
            loading:false,
            exportLoading:false,
            columns:[],
            isRecordList:false,
            pageParams:{
                currentPage: 1,
                pageCount: 50,
                perPage: 10,
                totalCount: 0
            },
            paramsData:{
              start_date:moment().startOf('quarter').format('YYYY-MM-DD'),
              end_date:moment().format('YYYY-MM-DD'),
              studio_id:'',
              filter_type:'month',
              filter_column:'money',
              course_cate_id:''
            },
            list:[],
            incomeDate:[],
            studios:[],
            filedLength:0,
            employee_columns:[],
            employee_list:[],
            courseCategorys:[],
            channel_columns:[],
            channel_list:[],
            course_columns:[],
            course_list:[],
            fee_columns:[],
            fee_list:[],
            title:'',
        }
    },
    mixins: [ tableMixins,ranges ],
    components: { moment , DatePicker , income, recordList},
    created(){
      this.paramsData.start_date = moment().startOf('quarter').format('YYYY-MM-DD')
      this.paramsData.end_date = moment().format('YYYY-MM-DD')
      this.rangesValue = [moment(this.paramsData.start_date,'YYYY-MM-DD'),moment(this.paramsData.end_date,'YYYY-MM-DD')]
      this.getStudio() 
      this.getCourseCategory()
    },
    computed: {
        RelativeWidth() {
            let width = 850
            if(this.filedLength >10){
              width = 900 + ( this.filedLength - 10 ) * 130
            }
            return width
        }
    },
    methods:{
        moment,
        notBeforeYear(date){
          const today = new Date()
          return date >= today
        },
        async getList(obj) {
            this.loading = true
            this.searchParams.search.year = this.year || Number(moment().format('YYYY'))
            let res = await this.$store.dispatch('analysisCampusAchievementAction',{search:this.paramsData})
            let timeStart = moment().valueOf()
            this.incomeDate = res.data.total_income
            this.title = `校区业绩数据统计`
            let list = res.data.income_list.rows
            list.forEach(item=>{
                item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total) : item.total
                item.average_value = this.paramsData.filter_column == 'money'?this.filterNum(item.average_value):item.average_value
                item.average_transaction_value = this.paramsData.filter_column == 'money'?this.filterNum(item.average_transaction_value):item.average_transaction_value
                item.filed.forEach(it=>{
                    item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
                })
            })
            let week = [
                { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                { title: '校区名称', width:'160px', dataIndex: 'studio_name',  key: 'studio_name', scopedSlots: { customRender: 'name' }, fixed: 'left'},
                { title: '金额', width:'120px', dataIndex: 'total', key: 'total',fixed: 'left',align:'right'},
                { title: '单数', width:'100px', dataIndex: 'count', key: 'count',fixed: 'left',align:'right'},
                { title: '占比', width:'100px', dataIndex: 'precent', key: 'precent',fixed: 'left',align:'right'},
                { title: '平均', width:'100px', dataIndex: 'average_value', key: 'average_value',fixed: 'left',align:'right'},
                { title: '客单价', width:'100px', dataIndex: 'average_transaction_value', key: 'average_transaction_value',fixed: 'left',align:'right'},
                { title: '付费试听', width:'100px', dataIndex: '付费试听', key: '付费试听',fixed: 'left',align:'right'},
                { title: '新签', width:'100px', dataIndex: '新签', key: '新签',fixed: 'left',align:'right'},
                { title: '续费', width:'100px', dataIndex: '续费', key: '续费',fixed: 'left',align:'right'},
                { title: '转介绍', width:'100px', dataIndex: '转介绍', key: '转介绍',fixed: 'left',align:'right'},
            ]
            if(list[0]){
              list[0].filed.forEach(item=>{
                  let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right',width:'90px'}
                  week.push(obj)
              })
            }
            let employee_list = res.data.employee_list.rows
            employee_list.forEach(item=>{
                item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total):item.total
                item.average_value = this.paramsData.filter_column == 'money'?this.filterNum(item.average_value):item.average_value
                item.average_transaction_value = this.paramsData.filter_column == 'money'?this.filterNum(item.average_transaction_value):item.average_transaction_value
                item.filed.forEach(it=>{
                    item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
                })
            })
            let employee_week = [
                { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                { title: '员工名称', width:'160px', dataIndex: 'employee_name',  key: 'name', fixed: 'left', scopedSlots: { customRender: 'name' }, ellipsis:true},
                { title: '金额', width:'120px', dataIndex: 'total', key: 'total',fixed: 'left',align:'right'},
                { title: '单数', width:'100px', dataIndex: 'count', key: 'count',fixed: 'left',align:'right'},
                { title: '占比', width:'100px', dataIndex: 'precent', key: 'precent',fixed: 'left',align:'right'},
                { title: '平均', width:'100px', dataIndex: 'average_value', key: 'average_value',fixed: 'left',align:'right'},
                { title: '客单价', width:'100px', dataIndex: 'average_transaction_value', key: 'average_transaction_value',fixed: 'left',align:'right'},
                { title: '付费试听', width:'100px', dataIndex: '付费试听', key: '付费试听',fixed: 'left',align:'right'},
                { title: '新签', width:'100px', dataIndex: '新签', key: '新签',fixed: 'left',align:'right'},
                { title: '续费', width:'100px', dataIndex: '续费', key: '续费',fixed: 'left',align:'right'},
                { title: '转介绍', width:'100px', dataIndex: '转介绍', key: '转介绍',fixed: 'left',align:'right'},
            ]
            if(employee_list[0]){
              employee_list[0].filed.forEach(item=>{
                  let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right',width:'90px'}
                  employee_week.push(obj)
              })
            }
            let channel_list = res.data.channel_list.rows
            channel_list.forEach(item=>{
                item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total):item.total
                item.average_value = this.paramsData.filter_column == 'money'?this.filterNum(item.average_value):item.average_value
                item.average_transaction_value = this.paramsData.filter_column == 'money'?this.filterNum(item.average_transaction_value):item.average_transaction_value
                item.filed.forEach(it=>{
                    item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
                })
            })
            let channel_week = [
                { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                { title: '渠道成单', width:'160px', dataIndex: 'channel_name',  key: 'name', fixed: 'left',scopedSlots: { customRender: 'name' },},
                { title: '金额', width:'120px', dataIndex: 'total', key: 'total',fixed: 'left',align:'right'},
                { title: '单数', width:'100px', dataIndex: 'count', key: 'count',fixed: 'left',align:'right'},
                { title: '占比', width:'100px', dataIndex: 'precent', key: 'precent',fixed: 'left',align:'right'},
                { title: '平均', width:'100px', dataIndex: 'average_value', key: 'average_value',fixed: 'left',align:'right'},
                { title: '客单价', width:'100px', dataIndex: 'average_transaction_value', key: 'average_transaction_value',fixed: 'left',align:'right'},
                { title: '付费试听', width:'100px', dataIndex: '付费试听', key: '付费试听',fixed: 'left',align:'right'},
                { title: '新签', width:'100px', dataIndex: '新签', key: '新签',fixed: 'left',align:'right'},
                { title: '续费', width:'100px', dataIndex: '续费', key: '续费',fixed: 'left',align:'right'},
                { title: '转介绍', width:'100px', dataIndex: '转介绍', key: '转介绍',fixed: 'left',align:'right'},
            ]
            if(channel_list[0]){
              channel_list[0].filed.forEach(item=>{
                  let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right',width:'90px'}
                  channel_week.push(obj)
              })
            }
            let course_list = res.data.course_list.rows
            course_list.forEach(item=>{
                item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total):item.total
                item.average_value = this.paramsData.filter_column == 'money'?this.filterNum(item.average_value):item.average_value
                item.average_transaction_value = this.paramsData.filter_column == 'money'?this.filterNum(item.average_transaction_value):item.average_transaction_value
                item.filed.forEach(it=>{
                    item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
                })
            })
            let course_week = [
                { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                { title: '课程科目', width:'160px', dataIndex: 'course_cate_name',  key: 'name', fixed: 'left',ellipsis:true},
                { title: '金额', width:'120px', dataIndex: 'total', key: 'total',fixed: 'left',align:'right'},
                { title: '单数', width:'100px', dataIndex: 'count', key: 'count',fixed: 'left',align:'right'},
                { title: '占比', width:'100px', dataIndex: 'precent', key: 'precent',fixed: 'left',align:'right'},
                { title: '平均', width:'100px', dataIndex: 'average_value', key: 'average_value',fixed: 'left',align:'right'},
                { title: '客单价', width:'100px', dataIndex: 'average_transaction_value', key: 'average_transaction_value',fixed: 'left',align:'right'},
                { title: '付费试听', width:'100px', dataIndex: '付费试听', key: '付费试听',fixed: 'left',align:'right'},
                { title: '新签', width:'100px', dataIndex: '新签', key: '新签',fixed: 'left',align:'right'},
                { title: '续费', width:'100px', dataIndex: '续费', key: '续费',fixed: 'left',align:'right'},
                { title: '转介绍', width:'100px', dataIndex: '转介绍', key: '转介绍',fixed: 'left',align:'right'},
            ]
            if(course_list[0]){
              course_list[0].filed.forEach(item=>{
                  let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right',width:'90px'}
                  course_week.push(obj)
              })
            }

            let fee_list = res.data.fee_list.rows
            fee_list.forEach(item=>{
                item.total = this.paramsData.filter_column == 'money'?this.filterNum(item.total):item.total
                item.average_value = this.paramsData.filter_column == 'money'?this.filterNum(item.average_value):item.average_value
                item.average_transaction_value = this.paramsData.filter_column == 'money'?this.filterNum(item.average_transaction_value):item.average_transaction_value
                item.filed.forEach(it=>{
                    item[it.filed_name] = this.paramsData.filter_column == 'money'?this.filterNum(it.filed_value):it.filed_value
                })
            })
            let fee_week = [
                { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index', fixed: 'left'},
                { title: '收费名称', width:'160px', dataIndex: 'fee_name',  key: 'name', fixed: 'left',ellipsis:true},
                { title: '金额', width:'120px', dataIndex: 'total', key: 'total',fixed: 'left',align:'right'},
                { title: '单数', width:'100px', dataIndex: 'count', key: 'count',fixed: 'left',align:'right'},
                { title: '占比', width:'100px', dataIndex: 'precent', key: 'precent',fixed: 'left',align:'right'},
                { title: '平均', width:'100px', dataIndex: 'average_value', key: 'average_value',fixed: 'left',align:'right'},
                { title: '客单价', width:'100px', dataIndex: 'average_transaction_value', key: 'average_transaction_value',fixed: 'left',align:'right'},
                { title: '付费试听', width:'100px', dataIndex: '付费试听', key: '付费试听',fixed: 'left',align:'right'},
                { title: '新签', width:'100px', dataIndex: '新签', key: '新签',fixed: 'left',align:'right'},
                { title: '续费', width:'100px', dataIndex: '续费', key: '续费',fixed: 'left',align:'right'},
                { title: '转介绍', width:'100px', dataIndex: '转介绍', key: '转介绍',fixed: 'left',align:'right'},
            ]
            if(fee_list[0]){
              fee_list[0].filed.forEach(item=>{
                  let obj = { title: item.filed_name, dataIndex: item.filed_name, key: item.filed_name,align:'right',width:'90px'}
                  fee_week.push(obj)
              })
            }

            if(list[0]){
              this.filedLength = list[0].filed.length
            }else{
              this.filedLength = 0
            }
            this.columns = week
            this.list = list
            this.channel_columns = channel_week
            this.channel_list = channel_list
            this.employee_columns = employee_week
            this.employee_list = employee_list
            this.course_columns = course_week
            this.course_list = course_list
            this.fee_columns = fee_week
            this.fee_list = fee_list
            this.loading = false
        },
        async getStudio() {
            let res = await this.$store.dispatch('searchBelongStudioAction', {})
            this.studios = res.data
        },
        async getCourseCategory() {
          let res = await this.$store.dispatch('searchCourseTypeAction', {})
          this.courseCategorys = res.data
        },
        handleChange(value){
          this.paramsData.studio_id = value;
        },
        handleButChange(e){
          this.paramsData.filter_type = e.target.value
          this.toScreen()
        },
        handleColButChange(e){
          this.paramsData.filter_column = e.target.value
          this.toScreen()
        },
        handleTypeButChange(e){
          if(this.paramsData.contract_type == e){
            this.paramsData.contract_type = undefined
          }else{
            this.paramsData.contract_type = e
          }
          this.toScreen()
        },
        handleSubjectChange(value){
          this.paramsData.course_cate_id = value
        },
        toScreen(){
          let obj = {
            search:this.paramsData
          }
          this.getList(obj);
        },
        // 获取一个月有几周
        getWeeks(year, month) {
            var d = new Date();
            // 该月第一天
            d.setFullYear(year, month-1, 1);
            var w1 = d.getDay();
            if (w1 == 0) w1 = 7;
            // 该月天数
            d.setFullYear(year, month, 0);
            var dd = d.getDate();
            // 第一个周一
            let d1;
            if (w1 != 1) d1 = 7 - w1 + 2;
            else d1 = 1;
            let week_count = Math.ceil((dd-d1+1)/7);
            return week_count;
        },
        filterNum(val){
          val = val.toString().replace(/\$|\,/g,'');
          if(isNaN(val)) {
            val = "0";  
          } 
          let sign = (val == (val = Math.abs(val)));
          val = Math.floor(val*100+0.50000000001);
          let cents = val%100;
          val = Math.floor(val/100).toString();
          if(cents<10) {
            cents = "0" + cents
          }
          for (var i = 0; i < Math.floor((val.length-(1+i))/3); i++) {
              val = val.substring(0,val.length-(4*i+3))+',' + val.substring(val.length-(4*i+3));
          }

          return (((sign)?'':'-') + val );
          // return (((sign)?'':'-') + val + '.' + cents);
        },
        showRecord(record){
          this.modalData = record
          this.isRecordList = true
        },
        hideRecord(){
          this.isRecordList = false
        },
    }
}
</script>
<style lang="scss">
  .staffTaget{
    .ant-table-row{
      td:nth-child(2){
        background: rgb(229, 244, 254);
      }
      td:nth-child(3){
        background: rgb(229, 244, 254);
      }
      td:nth-child(4){
        background: rgb(229, 244, 254);
      }
      td:nth-child(5){
        background: rgb(229, 244, 254);
      }
      td:nth-child(6){
        background: rgb(229, 244, 254);
      }
      td:nth-child(7){
        background: rgb(229, 244, 254);
      }
      td:nth-child(8){
        background: rgb(229, 244, 254);
      }
      td:nth-child(9){
        background: rgb(229, 244, 254);
      }
      td:nth-child(10){
        background: rgb(229, 244, 254);
      }
      td:nth-child(11){
        background: rgb(229, 244, 254);
      }
    }
    .employee_list {
      .ant-table-row{
      td:nth-child(8){
        background: rgb(229, 244, 254);
      }
      td:nth-child(9){
        background: rgb(229, 244, 254);
      }
      td:nth-child(10){
        background: rgb(229, 244, 254);
      }
      td:nth-child(11){
        background: rgb(229, 244, 254);
      }
    }
    }
    .table-name{
      width: 140px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
</style>